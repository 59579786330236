import Vue from 'vue'
import router from './router'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import Api from './plugins/api';

Vue.config.productionTip = false

Vue.use(Api, {
	basePath: process.env.VUE_APP_API_ROOT
});

Vue.session.get('user').then(user => {
	if (user) {
		window.user = JSON.parse(user)
	}
	new Vue({
		router,
		vuetify,
		render: h => h(App)
	}).$mount('#app')
})
