<template>
  <v-app>
    <v-app-bar
      app
      color="white"
      dark
      :elevation="3"
      elevate-on-scroll
    >
      <div class="d-flex align-center">
        <v-img
          alt="IADVL Logo"
          class="shrink mr-2"
          contain
          src="https://iadvlkerala.org/public/img/logo.svg"
          transition="scale-transition"
          width="200"
        />

        <h1 class="black--text body-1 d-none d-sm-block">Voting portal</h1>
      </div>

      <v-spacer></v-spacer>
      <div v-if="isLoggedIn">
        <span class="d-none d-sm-inline-block black--text caption">Welcome {{ user.title }}</span>
        <v-btn
          target="_blank"
          text
          @click="logout"
          color="black"
          title="Logout"
          class="pr-0"
        >
          <v-icon>mdi-lock-open-outline</v-icon>
        </v-btn>
      </div>
    </v-app-bar>

    <v-main>
      <router-view @login="setUser"/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    user: window.user || null
  }),

  computed: {
    isLoggedIn () {
      return !!this.user
    }
  },

  methods: {
    async setUser (user) {
      await this.$session.set('user', user)
      window.user = user
      this.user = user
      this.$router.push('./')
    },
    async logout () {
      this.user = null
      window.user = null
      await this.$session.unset('user')
      this.$router.push({ name: 'Login' })
    }
  }
};
</script>

<style>
  .v-toolbar__content {
    border-bottom: solid 1px #d2dfe8;
    background-color: #ecf7ff;
  }
</style>
