export default function (Vue) {

	const session = {
		set (key, val) {
			return new Promise((resolve) => {
				window.sessionStorage.setItem(key, JSON.stringify(val));
				resolve()
			})
		},
		get (key) {
			return new Promise((resolve) => {
				const val = window.sessionStorage.getItem(key);
				resolve(val)
			})
		},
		unset (key) {
			return new Promise((resolve) => {
				window.sessionStorage.removeItem(key);
				resolve()
			})
		}
	}

	Vue.session = Vue.prototype.$session = session

}