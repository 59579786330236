import Vue from 'vue'
import Router from 'vue-router'
import Session from '../plugins/session';

Vue.use(Session);
Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: __dirname,
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import('@/pages/Home.vue'),
      beforeEnter: (to, from, next) => {
        if (!isLoggedIn()) {
          return next({ path: '/login' })
        }
        next()
      }
    },
    {
      path: '/election/:id',
      name: 'ElectionDetail',
      component: () => import('@/pages/ElectionDetail.vue'),
      beforeEnter: (to, from, next) => {
        if (!isLoggedIn()) {
          return next({ path: '/login' })
        }
        next()
      }
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import('@/pages/Login.vue'),
      beforeEnter: (to, from, next) => {
        next(isLoggedIn() ? { path: '/' } : undefined)
      }
    }
  ]
})

router.beforeEach((to, from, next) => {
  // redirect 404 into login page
  next(!to.matched.length ? '/login' : undefined)
})

function isLoggedIn () {
    return !!window.user
}

export default router
