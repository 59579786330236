export default function (Vue, options) {
	const basePath = options.basePath
	const apiPath = basePath + 'api/'

	Vue.prototype.$basePath = basePath
	Vue.prototype.$apiPath = apiPath

	Vue.prototype.$get = async function (path) {
		const response = await fetch(apiPath + path)
		return response.json()
	}

	const postOrPut = async function(url = '', data = {}) {
		// Default options are marked with *
		const response = await fetch(apiPath + url, {
			method: 'POST', // *GET, POST, PUT, DELETE, etc.
			mode: 'cors', // no-cors, *cors, same-origin
			cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
			credentials: 'same-origin', // include, *same-origin, omit
			headers: {
				'Content-Type': 'application/json'
			},
			redirect: 'follow', // manual, *follow, error
			referrerPolicy: 'strict-origin-when-cross-origin', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
			body: JSON.stringify(data) // body data type must match "Content-Type" header
		});

		return response.json(); // parses JSON response into native JavaScript objects
	}

	Vue.prototype.$post = postOrPut
	Vue.prototype.$put = postOrPut

}